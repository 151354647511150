
/* NAVIGATION TABS
================================*/
.navigation-tabs{
	width: 100%;
	float: left;
	display: block;
	position: relative;
	background-color: $navigation-tabs-background-color;
	box-shadow: $navigation-tabs-box-shadow;
	
	@media (min-width: $landscape-width){
		&:after{
			content: "";
			width: 80%;
			height: 100%;
			position: absolute;
			right: 0;
			background-color: $navigation-tabs-background-color-hover;
			z-index: 99;
		}
	}
}

.navigation-tabs-nav{
	width: 100%;
	margin: 0;
	padding: 0;
	float: left;
	list-style: none;
	
	@media (min-width: $landscape-width){
		width: 20%;
	}
	
	> li{
		a{
			width: 100%;
			padding: $navigation-tabs-link-padding;
			float: left;
			font-size: $navigation-tabs-link-font-size;
			text-decoration: none;
			color: $navigation-tabs-font-color;
			outline: 0;
			background-color: $navigation-tabs-background-color;
			@include transition(background .3s);
		}
		
		&.is-active a,
		&:hover a,
		&:active a{
			background-color: $navigation-tabs-background-color-hover;
		}
	}
}

.navigation-tabs-pane{
	width: 100%;
	min-height: 30px;
	padding: $navigation-tabs-pane-padding;
	float: right;
	display: none;
	position: relative;
	opacity: 0;
	font-size: $navigation-tabs-pane-font-size;
	color: $navigation-tabs-font-color;
	z-index: 100;
	background-color: $navigation-tabs-background-color-hover;
	@include transition(opacity .5s);
	
	&.is-active{
		display: block;
		opacity: 1;
	}
	
	@media (min-width: $landscape-width){
		width: 80%;
	}
}
