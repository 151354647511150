// Custom CSS Rules
:root {
  --transition-all: all ease-in-out 0.3s;
  --font-family: "Montserrat", sans-serif; //400, 600, 800

  --kirmizi: #e40327;
  --koyu-kirmizi: #a7001b;
  --koyu-gri: #252222;
  --fume: #2b2828;
  --lacivert: #0d468d;
  --koyu-lacivert: #032854;
  --gri: #818283;
  --acik-gri: #ececec;
  --transition: all ease-in-out 0.2s;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// * {
//   //outline: 1px solid limegreen !important;
//   background: rgb(0 100 0 / 0.1) !important;
// }

html,
body {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  color: #555;
  line-height: 1.8;
  width: 100%;
  height: 100%;
  scroll-behavior: smooth;
  background-color: var(--acik-gri);
  position: relative;
  overflow-x: hidden;
}

body {
  //background-color: var(--acik-gri);
  color: var(--koyu-gri);
}

a {
  transition: var(--transition-all);
  text-decoration: none;
}

.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  //font-family: var(--heading-font);
  font-weight: 600;
  color: var(--mor);
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.hidden-desktop {
  @include desktop {
    display: none;
  }
}

.hidden-mobil {
  @include mobil {
    display: none;
  }
}

section.section {
  padding-block: clamp(2rem, 6vw, 7rem);
}

.text-kirmizi {
  color: var(--kirmizi);
}
.text-koyu-kirmizi {
  color: var(--koyu-kirmizi);
}
.text-lacivert {
  color: var(--lacivert);
}

.bg-kirmizi {
  background-color: var(--kirmizi) !important;
}

.bg-koyu-gri {
  background-color: var(--koyu-gri) !important;
}

.bg-dark {
  background-color: var(--fume);
}
.bg-lacivert {
  background-color: var(--lacivert);
}

strong {
  font-weight: bold;
}

button,
.btn {
  border-radius: 0;
}

input,
textarea,
select {
  border-radius: 0 !important;
  border: none;
  background-color: #ffffff10 !important;
  color: #fff !important;
}

ul.custom-list {
  list-style: none;
  padding-left: 20px;

  li {
    &::before {
      color: var(--kirmizi);
      content: "\00bb";
      display: inline-block;
      margin-right: 8px;
      font-size: 150%;
      line-height: inherit;
      margin-left: -20px;
    }
  }
}

.siyah-link {
  color: var(--koyu-gri);
  font-weight: 400;

  &:hover {
    color: var(--koyu-kirmizi);
  }
}

.px-lg-none {
  @media (min-width: 992px) {
    padding-inline: 0 !important;
  }
}

.hidden-mobile {
  @media (max-width: 767px) {
    display: none;
  }
}

.hidden-desktop {
  @media (min-width: 768px) {
    display: none;
  }
}

header {
  background-color: #fff;
  //color: #fff;
  position: relative;
  z-index: 100;
}

.section-padding {
  padding-block: 2rem;

  @media (min-width: 992px) {
    padding-block: 3rem;
  }

  @media (min-width: 1200px) {
    padding-block: 4rem;
  }
}

// Navigation
.navigation-item:not(.navigation-brand-text):hover .navigation-link,
.navigation-item:not(.navigation-brand-text):focus .navigation-link,
.navigation-item:not(.navigation-brand-text).is-active .navigation-link {
  color: var(--kirmizi);
}

.navigation-avatar-item .navigation-link img {
  border: solid 2px var(--kirmizi);
}

.navigation-item:hover > .navigation-link > .submenu-indicator:after,
.navigation-item:focus > .navigation-link > .submenu-indicator:after,
.navigation-item.is-active > .navigation-link > .submenu-indicator:after {
  border-color: transparent var(--kirmizi) var(--kirmizi) transparent;
}

.navigation-btn {
  border-color: var(--kirmizi);
  background-color: var(--kirmizi);
}

.navigation-btn:hover,
.navigation-btn:focus {
  background-color: #6254ee;
}

.navigation-badge {
  background-color: var(--kirmizi);
}

.navigation-input:hover,
.navigation-input:focus {
  border-color: var(--kirmizi);
}

.navigation-btn:hover .navigation-search-icon {
  color: var(--kirmizi);
}

.navigation-dropdown-item:hover > .navigation-dropdown-link,
.navigation-dropdown-item:focus > .navigation-dropdown-link,
.navigation-dropdown-item.is-active > .navigation-dropdown-link {
  color: var(--kirmizi);
}

.navigation-dropdown-item:hover > .navigation-dropdown-link > .submenu-indicator:after,
.navigation-dropdown-item:focus > .navigation-dropdown-link > .submenu-indicator:after,
.navigation-dropdown-item.is-active > .navigation-dropdown-link > .submenu-indicator:after {
  border-color: transparent var(--kirmizi) var(--kirmizi) transparent;
}

.navigation-list > li > a:hover {
  color: var(--kirmizi);
}

.navigation-dropdown-link {
  border-left: 6px solid var(--kirmizi);
}

.navigation-dropdown-item:hover .navigation-dropdown-link {
  background-color: var(--lacivert);
}

.navigation-link {
  text-transform: uppercase;
}

.navigation-link,
.navigation-dropdown-link {
  font-weight: 800;
}

#navigation .navigation-link {
  @media (max-width: 1199px) {
    font-size: 13px;
  }

  @media (max-width: 991px) {
    padding-block: 17px;
  }
}

.navigation-header .navigation-logo {
  @media (max-width: 991px) {
    transform: translateX(-50%);
    margin-left: 50%;
  }
}

.menu-bize-sorun {
  background-color: var(--kirmizi);
  position: relative;

  a {
    color: #fff;

    &:hover {
      color: var(--koyu-gri) !important;
    }
  }

  .icon {
    font-size: 20px !important;
  }

  &::after {
    @media (min-width: 992px) {
      content: "";
      display: block;
      background-color: var(--kirmizi);
      min-width: 2000px;
      height: 100%;
      left: 100%;
      position: absolute;
    }
  }
}

// Main Hero
.hero {
  background-color: var(--koyu-gri);
  position: relative;

  & > *:not(#waveCanvas) {
    z-index: 2;
    position: relative;
  }
}

.slider-ok {
  --boyut: 40px;
  color: var(--kirmizi);
  font-size: var(--boyut);
  z-index: 3;
  line-height: 1 !important;
  transition: all ease-in-out 0.2s;

  &.slider-ok-sag {
    transform: scale(-1);
  }

  .icon {
    width: var(--boyut);
    height: var(--boyut);
  }

  &:hover {
    opacity: 0.8;
  }
}

.hero-slider {
  position: relative;
  background-color: var(--koyu-lacivert);

  // @media (max-width: 767px) {
  //   height: calc(100vh - 60px);
  // }

  @media (max-height: 730px) {
    height: calc(100vh - 60px);
  }

  .swiper {
    @media (max-width: 767px) {
      height: 100%;
    }

    @media (max-height: 730px) {
      height: 100%;
    }
  }

  .swiper-slide {
    position: relative;
    isolation: isolate;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      content: "";
      display: block;
      height: 45%;
      background: linear-gradient(0deg, rgba(3, 40, 84, 1) 5%, rgba(3, 40, 84, 0) 100%);
    }

    .slider-slogan {
      position: absolute;
      font-size: 2.2rem;
      font-weight: 600;
      bottom: 60px;
      color: #fff;
      left: 35px;
      //transform: translate(-50%);
      line-height: 1.2;
      z-index: 3;

      @media (min-width: 768px) {
        font-size: 3.5rem;
        left: 10%;
        max-width: 60%;
      }
    }
  }

  .slider-slogan__text {
    text-shadow: 4px 4px 0 var(--koyu-lacivert);
  }

  .swiper-slide img {
    width: 100%;
    min-height: 100%;
    position: relative;
    z-index: 1;

    animation: kenburns 40s ease;
    /* Add infinite to loop. */
    //position: absolute;
  }

  .swiper-pagination {
    @media (min-width: 768px) {
      text-align: right;
      bottom: 50px;
      padding-right: 46px;
      position: absolute;
    }
  }
}

@keyframes kenburns {
  0%,
  100% {
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
    transform: scale(1);
    -ms-transform: scale(1);
    /* IE 9 */

    -webkit-transform: scale(1);
    /* Safari and Chrome */

    -o-transform: scale(1);
    /* Opera */

    -moz-transform: scale(1);
    /* Firefox */
  }

  50% {
    transform: scale(1.1);
    -ms-transform: scale(1.1);
    /* IE 9 */

    -webkit-transform: scale(1.1);
    /* Safari and Chrome */

    -o-transform: scale(1.1);
    /* Opera */

    -moz-transform: scale(1.1);
    /* Firefox */
  }
}

.swiper-pagination {
  .swiper-pagination-bullet {
    border-radius: 0;
    width: 20px;
    height: 2px;
    background-color: #fff;
    opacity: 78;
    transition: all ease-in-out 0.2s;

    &.swiper-pagination-bullet-active {
      height: 4px;
      background-color: var(--kirmizi);
    }
  }
}

.main-hakkimizda-video {
  background-color: var(--koyu-lacivert);
  padding-block: 6vh;

  .desktop-margin-negative {
    position: relative;
    z-index: 3;
    margin-top: -50px;
  }
}

.hakkimizda-video {
  iframe {
    display: block;
  }
}

.video-placeholder {
  width: 100%;
}

.main-slogan {
  background-color: var(--koyu-lacivert);
  color: #fff;
  text-align: center;
}

.main-slogan {
  h2 {
    font-size: 2rem;

    .slogan-buyuk {
      font-weight: 600;
      font-size: 3rem;

      @media (max-width: 991px) {
        font-size: 1.8rem;
      }
    }
  }

  padding-block: 1rem 3rem;
}

.main-scroll {
  color: #fff;
  background-color: var(--koyu-lacivert);
  position: relative;
  font-size: 30px;
  text-align: center;

  //margin-bottom: 5rem;
  @media (max-width: 767px) {
    margin-bottom: 50px;
  }

  a {
    color: currentColor;
    width: 80px;
    height: 80px;
    display: grid;
    place-items: center;
    background-color: var(--koyu-lacivert);
    border-radius: 50%;
    position: absolute;
    bottom: -40px;
    left: calc(50% - 40px);

    i {
      animation: mouseJump 2s ease infinite;
    }
  }
}

@keyframes mouseJump {
  0%,
  100% {
    transform: translateY(-10px);
  }

  50% {
    transform: translateY(10px);
  }
}

.section-baslik {
  color: #fff;
  background-color: var(--kirmizi);
  font-weight: 600;
  letter-spacing: 3px;
  font-size: 26px;
  padding: 10px 25px;
  position: relative;
  text-transform: uppercase;
  display: inline-block;

  &::after {
    position: absolute;
    bottom: -12px;
    right: -10px;
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid var(--koyu-kirmizi);
    transform: rotate(225deg);
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

// .main-projeler-slider .swiper {
//   overflow: visible;
// }
.duyurular-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3px;

  @media (min-width: 992px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
}

.duyuru-card {
  display: block;
  transition: var(--transition);
  background-color: var(--koyu-gri);
  position: relative;
  overflow: hidden;
  aspect-ratio: 1/1;
  isolation: isolate;

  &:after {
    position: absolute;
    content: "";
    display: block;
    width: 100%;
    height: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
    z-index: 1;
  }

  &:hover {
    img {
      transform: scale(1.1);
      opacity: 0.5;
    }

    .duyuru-card-title {
      bottom: 2.4rem;
    }

    .duyuru-link {
      opacity: 1;
      font-weight: 800;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: var(--transition);
    position: relative;
    opacity: 0.7;
    z-index: -1;
  }

  .duyuru-card-title {
    position: absolute;
    left: 1.4rem;
    right: 1.4rem;
    bottom: 1rem;
    color: #fff;
    font-weight: 600;
    z-index: 2;
    transition: var(--transition);
    font-size: 1.1rem;
    line-height: 1.1;
    margin-bottom: 8px;
  }

  .duyuru-link {
    font-size: 0.9rem;
    color: #ff3859;
    position: absolute;
    bottom: 1rem;
    left: 1.4rem;
    letter-spacing: 1px;
    z-index: 2;
    opacity: 0;
    transition: var(--transition);
  }

  .badge {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 4;
    text-transform: uppercase;
  }
}

.duyuru-img-wrapper {
  position: relative;
  isolation: isolate;

  .badge {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
    font-size: 0.9rem;
    text-transform: uppercase;
  }
}

.sayilarla-tigader {
  background-color: #f0f5fc;
  padding-block: 40px;

  @media (min-width: 992px) {
    padding: 0;
  }
}

.sayilarla-grid-item {
  display: grid;
  place-items: center;
  color: var(--koyu-gri);
  font-weight: 400;
  font-size: 24px;
  line-height: 1.2;

  @media (max-width: 991px) {
    font-size: 18px;
  }

  @media (min-width: 992px) {
    aspect-ratio: 1/1;
  }
}

.sayilarla-grid-item__title {
  color: var(--kirmizi);
  text-align: center;
  position: relative;
  font-weight: 600;
  font-size: clamp(1.4em, 3vw, 2.2em);
  margin-bottom: 25px;

  @media (min-width: 992px) {
    background-color: var(--kirmizi);
    color: #fff;
    margin: 0;
  }

  &::before {
    @media (min-width: 992px) {
      position: absolute;
      content: "";
      display: inline-block;
      background-color: var(--kirmizi);
      height: 100%;
      width: 2000px;
      right: 100%;
      top: 0;
      bottom: 0;
    }
  }
}

.countup-item {
  text-align: center;
  line-height: 1;

  .icon {
    color: var(--kirmizi);
    font-size: 2em;
  }

  .countup-item__sayi {
    font-size: 2em;
    font-weight: 600;

    @media (min-width: 992px) {
      font-size: 4rem;
    }
  }

  .countup-item__title {
    text-transform: uppercase;
    font-size: 0.8em;
  }
}

.main-tesislerimiz-grid {
  display: grid;
  gap: 10px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
}

.tesis-card {
  font-size: 14px;
  height: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    opacity: 0.8;
  }

  img {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    border-bottom: 3px solid var(--kirmizi);
  }

  .tesis-card-body {
    background-color: var(--koyu-gri);
    color: #fff;
    text-align: center;
    flex-grow: 1;
    padding: 1rem;

    .tesis-card__title {
      font-size: 1em;
      font-weight: 600;
      margin-bottom: auto;
      text-transform: uppercase;
      margin-bottom: 15px;
    }

    .tesis-card__text {
      opacity: 0.5;
      font-size: 0.8rem;
    }
  }
}

.bizi-taniyin-card {
  background-color: var(--koyu-gri);
  padding: 2rem;
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 200px;

  @media (min-width: 992px) {
    min-height: 400px;
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.3;
    inset: 0;
    z-index: 1;
    filter: grayscale(100);
  }

  & > div {
    position: relative;
    z-index: 2;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
  }

  .bizi-taniyin-card__detay-icon {
    background-color: #fff;
    color: var(--kirmizi);
    display: grid;
    place-items: center;
    font-size: 30px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    align-self: flex-end;
    opacity: 0;
    transition: var(--transition);
  }

  .bizi-taniyin-title {
    font-weight: 600;
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #fff;
  }

  p {
    color: #fff;
  }

  &:hover {
    background-color: var(--koyu-kirmizi);
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.2);

    .bizi-taniyin-card__detay-icon {
      opacity: 1;
    }

    .bizi-taniyin-title,
    p {
      color: #fff;
    }
  }
}

.bizi-yakindan-taniyin {
  .bg-koyu-gri {
    padding-block: 5rem;

    @media (max-width: 991px) {
      padding-block: 3rem;
    }
  }

  & > .container {
    margin-bottom: -30px;
  }
}

footer {
  background-image: url(../img/footer-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-block: 6vh;
  color: #fff;
  font-size: 13px;

  @media (max-width: 991px) {
    text-align: center;
  }

  a {
    color: #fff;

    &:hover {
      color: var(--kirmizi);
    }
  }

  address {
    line-height: 2;
  }
}

.footer-list__title {
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 8px;
  font-size: 1.2em;
}

.footer-list {
  ul {
    list-style: none;
    padding-left: 0;

    li {
      line-height: 3;

      @media (max-width: 991px) {
        line-height: 2;
      }
    }
  }
}

.footer-logo {
  margin-bottom: 35px;

  @media (max-width: 991px) {
    max-width: 70%;
    display: block;
    margin-inline: auto;
  }
}

.alt-footer {
  display: flex;
  font-size: 12px;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  border-top: 1px solid #ffffff40;
  color: #ffffff70;

  .kroma a {
    color: #ffffff70;

    &:hover {
      color: #fff;
    }
  }

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
  }
}

.footer-social {
  padding-top: 30px;
  font-size: 1.5rem;
  display: flex;
  gap: 20px;

  @media (max-width: 991px) {
    justify-content: center;
  }
}

.footer-galeri-slider {
  img {
    width: 100%;
    height: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
  }
}

.alt-sayfa-baslik {
  color: #fff;
  background-color: var(--lacivert);
  padding-block: 1rem;

  .baslik-text {
    letter-spacing: 1px;
    opacity: 0.9;
    font-size: 14px;
    font-weight: 600;
  }

  a.back-link {
    color: #fff;
    font-size: 90%;
  }
}

// Hakkımızda
.about {
  // background-color: var(--koyu-gri);
  // color: #fff;

  .lead-text {
    font-size: larger;
    line-height: 1.8;
  }
}

.blockquote {
  padding-left: 50px;
  border-left: 4px solid var(--kirmizi);
}

.form-card {
  background-color: var(--fume);
  border: 1px solid var(--gri);
  padding: 2rem;
  border-radius: 1rem;
}

.tesis-foto-galeri {
  img {
    width: 100%;
    aspect-ratio: 4 / 3;
    object-fit: cover;
    transition: var(--transition);

    &:hover {
      transform: scale(1.1);
    }
  }
}

.sube-card {
  display: flex;
  flex-direction: column;
  color: #fff;
  background-color: var(--koyu-gri);
  border: 1px solid var(--fume);
  box-shadow: 0 15px 25px rgba(255, 255, 255, 0.1);

  &:hover {
    color: var(--kirmizi);

    img {
      opacity: 0.7;
    }
  }

  img {
    border-bottom: 3px solid var(--kirmizi);
  }

  .sube-card-link {
    font-weight: bold;
    text-align: center;
    padding: 15px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}

.istasyon-gorsel {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 4 / 3;
}

a[data-fancybox="istasyon"] {
  transition: var(--transition);

  &:hover {
    opacity: 0.7;
  }
}

.beton-cesitleri {
  background-image: url(../img/duvar.jpg);
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}

.beton-card {
  width: 100%;
  aspect-ratio: 1 / 1;
  position: relative;
  border-radius: 100vw;
  display: grid;
  place-items: center;
  overflow: hidden;
  font-weight: 600;
  color: #000;
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.3);
  border: 3px solid transparent;
  transition: var(--transition);

  &:hover {
    border-color: var(--kirmizi);

    img {
      filter: blur(2px);
    }
  }

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    inset: 0;
    z-index: 1;
  }

  .beton-card-text {
    z-index: 2;
    font-size: clamp(2rem, 5vw, 3rem);
    mix-blend-mode: multiply;
    opacity: 0.75;
  }
}

.uretim-teknolojileri-grid {
  display: grid;
  gap: 2px;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.uretim-teknoloji-card {
  background-color: #00000070;
  color: #fff;
  padding: 2rem;
  font-size: 13px;
  transition: var(--transition);

  &:hover {
    background-color: var(--kirmizi);
  }
}

.uretim-teknoloji-card__title {
  font-weight: 600;
  font-size: 1.5rem;
}

.urunlerimiz-card {
  display: grid;
  place-items: center;
  aspect-ratio: 4 / 3;
  position: relative;
  background-color: #000;
  color: #fff;
  font-weight: 800;
  letter-spacing: 1;
  font-size: 2rem;
  overflow: hidden;

  &:hover {
    color: #000;
    background-color: var(--kirmizi);

    img {
      opacity: 0.3;
    }
  }

  img {
    position: absolute;
    height: 100%;
    object-fit: cover;
    opacity: 0.8;
  }

  .title {
    position: relative;
    z-index: 2;
    text-align: center;
    line-height: 1;
    text-shadow: 0 0 25px rgba(0, 0, 0, 0.3);
  }
}

.merkez-adres {
  text-align: center;

  h2 {
    font-weight: 600;
    //color: var(--koyu-kirmizi);
    text-align: center;
  }
}

.adres-diger {
  @media (min-width: 992px) {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: center;
  }
}

.adres-mail a {
  color: var(--koyu-kirmizi);
}

.tesis-adresler {
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  padding-block: 2rem;
}

.tesis-adres-card {
  font-size: 12px;
  padding: 1.3rem;
  text-align: center;
  background-color: #fff;
  height: 100%;

  .adres-title {
    font-size: 1.2em;
    text-transform: uppercase;
  }
}

.iletisim-formu {
  background-color: #fff;
  padding: 2rem;
}

.kroki {
  height: 100%;
  background-color: var(--koyu-gri);

  iframe {
    height: 100%;
  }
}

.avni-saglam-imza {
  max-width: 250px;
}

.main-baskandan {
  padding-top: clamp(30px, 5vw, 70px);
  position: relative;
  overflow: hidden;
}

.baskandan-avni-saglam {
  @include desktop {
    position: absolute;
    width: 50%;
    right: 2%;
    bottom: -20%;
  }
  @include mobil {
    //width: 50%;
    margin-inline: auto;
    aspect-ratio: 3 / 2;
    overflow: hidden;
  }

  img {
    @include mobil {
      width: 100%;
    }
  }
}

.maganager-card {
  background-color: var(--koyu-gri);
  color: #fff;
  text-align: center;
  font-size: 13px;
  line-height: 1.4;
  height: 100%;
}

.manager-card__img {
  aspect-ratio: 3 / 4;
  object-fit: cover;
  width: 100%;
}

.manager-card-body {
  padding: 15px;
}

.manager-card__name {
  font-weight: 800;
}

.temsilcilik-card {
  display: block;

  &:hover {
    .temsilcilik-card-body {
      color: var(--kirmizi);
    }

    .temsilcilik-card-header {
      box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    }
  }
}

.temsilcilik-card-header {
  background-color: var(--koyu-gri);
  margin-bottom: 10px;
  border-radius: 6px;
  transition: var(--transition-all);

  img {
    aspect-ratio: 4 / 3;
    width: 100%;
    object-fit: cover;
    object-position: top center;
    border-radius: 5px;
  }
}

.temsilcilik-card-body {
  color: var(--koyu-gri);
  font-size: 14px;
  line-height: 1.4;
  transition: var(--transition-all);
}

.temsilcilik-card-name {
  font-weight: 800;
  color: var(--lacivert);
}

.temsilcilik-detay-ust {
  background-color: #dedede;
  padding-top: 35px;
}

.temsilci-detay-img {
  aspect-ratio: 1 / 1;
  overflow: hidden;
  width: 100%;
  object-fit: cover;
  object-position: top center;
}

.duyuru-tarih {
  background-color: var(--lacivert);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  border-inline-start: 5px solid var(--kirmizi);
  letter-spacing: 1px;
  padding: 5px 10px;
  line-height: 1.2;
  border-radius: 4px;
  margin-bottom: 20px;
}

.diger-haber-card {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 13px;
  padding: 8px;

  &:not(:last-child) {
    margin-bottom: 6px;
  }

  &:hover {
    img {
      opacity: 1;
    }
    .diger-haber-card-title {
      color: #000;
    }
  }
}

.diger-haber-card-img {
  aspect-ratio: 1 / 1;
  width: 80px;
  object-fit: cover;
  border-radius: 4px;
  transition: var(--transition-all);
  opacity: 0.7;
}

.diger-haber-card-title {
  color: #666;
  transition: var(--transition-all);
}

.diger-haber-card-tarih {
  color: var(--koyu-kirmizi);
  font-weight: 600;
}

.egitim-haber-card {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  color: #666666;

  img {
    width: 100%;
    aspect-ratio: 16 / 9;
    border: 2px solid #666;
    object-fit: cover;
    border-radius: 10px;
  }
}

.egitim-haber-card__title {
  font-weight: 600;
  font-size: 14px;
  @include line-clamp(2);
  line-height: 1.2;
}

.egitim-haber-slider {
  .swiper-pagination {
    bottom: -30px;
    .swiper-pagination-bullet {
      border-radius: 0;
      width: 10px;
      height: 2px;
      background-color: #626262;
      transition: all ease-in-out 0.2s;

      &.swiper-pagination-bullet-active {
        height: 4px;
        background-color: var(--kirmizi);
      }
    }
  }
}

.egitimler-grid {
  display: grid;
  gap: 15px;
}

.egitim-card {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 15px;

  &:hover {
    .egitim-card-body {
      color: var(--lacivert);
    }

    img {
      opacity: 1;
    }
  }
}

.egitim-card-img {
  width: 100px;
  height: 100px;
  border: 1px solid #ededed;
  flex-grow: 0;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.7;
    transition: var(--transition-all);
  }

  @include desktop {
    width: 150px;
    height: 150px;
  }
}

.egitim-card-body {
  line-height: 1.3;
  color: #252222;
  transition: var(--transition-all);
}

.egitim-card-body-title {
  font-size: 17px;
  font-weight: 800;
}

.egitim-card-body-ozet {
  font-size: 13px;
}

.egitim-bilgi {
  font-size: 12px;
}

.egitim-detay-vitrin {
  position: relative;
  img {
    position: sticky;
    top: 20px;
  }
}

.egitim-detay-bilgi {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 3px 20px;
  font-size: 14px;
}

.egitim-detay-bilgi__title {
  font-weight: 800;
}

.galeri-card {
  display: flex;
  align-items: center;
  gap: 15px;
  background-color: #ffffff;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding: 8px 14px;
  border-radius: 10px;
  color: #2b2828;

  &:hover {
    background-color: var(--lacivert);
    color: #fff;
  }

  @include mobil {
    flex-direction: column;
    align-items: flex-start;
  }

  span.btn {
    @include mobil {
      display: block;
      width: 100%;
    }
  }

  .file-icon {
    font-size: 2rem;
    color: var(--kirmizi);
  }
}

.galeri-card-img {
  display: block;
  width: 100px;
  height: 65px;
  border-radius: 5px;
  flex-grow: 0;
  flex-shrink: 0;
  object-fit: cover;
  border: 1px solid #ebebeb;

  @include mobil {
    display: none;
  }
}

.galeri-card-body {
  flex-grow: 1;
  line-height: 1.4;
}

.galeri-card-body__title {
  font-weight: 800;
}

.galeri-detay-card {
  aspect-ratio: 1 / 1;
  width: 100%;
  display: grid;
  border: 1px solid #bebebe;
  background-color: #fff;
  place-items: center;
  padding: 10px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-height: 200px;
  }
}

.video-card {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 15px;
  color: var(--lacivert);
  background-color: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  transition: var(--transition-all);

  &:hover {
    background-color: var(--lacivert);
    color: #fff;

    img {
      opacity: 1;
    }
  }
}

.video-card-video {
  a {
    display: block;
  }

  img {
    opacity: 0.8;
    transition: var(--transition-all);
  }
}

.video-card-title {
  font-weight: 800;
  @include ellipsis;
}

.basin-kiti-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.basin-kiti-card-body {
  padding: 15px 25px;
  border-top: 1px solid #ebebeb;
  font-size: 13px;
}

.basin-kiti-card-title {
  font-weight: 700;
}

.basin-kiti-card-header {
  height: 250px;
  display: grid;
  place-items: center;
  //padding: 40px;

  background-position: 0px 0px, 10px 10px;
  background-size: 20px 20px;
  background-image: linear-gradient(45deg, #eee 25%, transparent 25%, transparent 75%, #eee 75%, #eee 100%), linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);

  &.header-dark {
    background-image: linear-gradient(45deg, #2f2f2f 25%, transparent 25%, transparent 75%, #2f2f2f 75%, #2f2f2f 100%), linear-gradient(45deg, #2f2f2f 25%, #000 25%, #000 75%, #2f2f2f 75%, #2f2f2f 100%);
  }

  img {
    display: block;
    height: 80%;
    width: 80%;
    object-fit: contain;
  }
}

.uye-card {
  display: block;
  isolation: isolate;
  aspect-ratio: 4 / 5;
  color: #fff;
  background-color: var(--lacivert);
  border-radius: 7px 7px 40px 7px;
  position: relative;
  width: 100%;
  overflow: hidden;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
    .uye-card-img {
      opacity: 1;
    }
    .uye-card-content {
      height: 55%;
    }
  }
}

.uye-card-img {
  inset: 0;
  position: absolute;
  z-index: -1;
  transition: var(--transition-all);
  opacity: 0.7;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
}

.uye-card-content {
  position: absolute;
  height: 40%;
  background-image: linear-gradient(transparent 0%, var(--lacivert) 80%);
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  justify-content: flex-end;
  gap: 8px;
  transition: var(--transition-all);
}

.uye-card-name {
  font-weight: 800;
  color: #fff;
  font-size: 18px;
}
.uye-card-sehir {
  font-weight: 500;
  color: #b8b1ff;
  font-size: 14px;
}

.uye-detay-foto {
  background-color: var(--lacivert);
  overflow: hidden;
  border-radius: 7px 7px 40px 7px;
}

.uye-detay-isim {
  color: var(--lacivert);
  font-size: clamp(2rem, 5vw, 4rem);
  margin-left: -4px;
}

.uye-detay-title {
  font-size: 16px;
  text-transform: uppercase;
}

.uye-detay-sehir {
  span:first-child {
    font-weight: 800;
    background-color: var(--kirmizi);
    padding: 3px 10px;
    border-left: 5px solid var(--koyu-kirmizi);
  }
  span:last-child {
    font-weight: 600;
    background-color: var(--lacivert);
    padding: 3px 10px;
  }
  font-size: 13px;
  color: #fff;
  letter-spacing: 2px;
  text-transform: uppercase;
  width: fit-content;
  line-height: 1.2;
}

.uye-detay-ilce {
  background-color: var(--lacivert);
  display: inline-block;
}

.uye-detay-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;

  @include desktop {
    flex-direction: row;
    gap: 25px;
  }
  i.icon {
    background-color: var(--lacivert);
    color: #fff;
    display: grid;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    place-items: center;
    line-height: 1;
    font-size: 16px;
  }
}

.uye-detay-info-item {
  display: flex;
  gap: 5px;
}

.bio {
  ::marker {
    color: var(--kirmizi);
  }
}

.search-input {
  display: flex;
  line-height: 2;
  border: 1px solid #ebebeb;
  padding-inline: 15px;
  gap: 15px;

  input {
    width: 100%;
  }

  input:focus,
  input:focus-visible {
    box-shadow: 0;
    outline: 0;
  }
}

.search-input:has(input:focus, input:focus-visible) {
  border-color: var(--lacivert);
}
